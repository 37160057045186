<template>
  <div>

    <div class="row">
      <div class="col-md-12">
        <Card :title="'Tambah Pengeluaran'">
          <template v-slot:body>
            <Form :form="form" route="expenses" type="add"/>
          </template>
        </Card>
      </div>
    </div>

  </div>
</template>

<script>

import Card from '@/view/content/Card.vue'
import Form from '@/component/expenses/Form.vue'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card,
    Form
  },

  data() {
    return {
      // Form
      form: {
        date: '',
        name: '',
        amount: '',
        flow_type: '1',
        expense_method: '1',
        expense_method_type: '1',
        expense_category_id: '',
        notes: '',
        photo: [],
        photo_name: "/images/default-expense.svg"
      },
    }
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Keuangan" },
      { title: "Tambah Pengeluaran" },
    ])
  },

}
</script>

<style>
</style>